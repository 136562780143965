
















































































import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/home/Breadcrumb.vue";
@Component({
    components: {
        "al-breadcrumb": Breadcrumb
    }
})
export default class ExpertList_2021 extends Vue {
    data: {} = {};

    getData() {
        const getOpt = this.$api.apiList.expertList_2021;
        const opt = getOpt();

        this.$axios({
            method: opt.method,
            url: opt.url,
        }).then((res: any) => {
            this.data = res.data.data;
        });
    }

    handelExpert(item: any, index: any) {
        this.$router.push({ name: "expert2021", params: { eid: item.id } });
    }

    mounted() {
        this.getData();
    }
}
